/**
 *
 * Light Model
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { Record, OrderedMap, Seq } from "immutable";
import { ascend, prop } from "ramda";
import { safeToInt } from "utils";
import zwaveDevicePropsFromJson, { defaultZwaveDeviceProps } from "./zwave";
export const LEVEL = {
  MIN: 0,
  MAX: 99
};
export const createLight = Record({
  ...defaultZwaveDeviceProps,
  nodeType: "LIGHT",
  level: 0,
  isDimmer: false,
  on: false
});

const createLightFromJson = json => {
  const level = safeToInt(json.level);
  return createLight({
    ...zwaveDevicePropsFromJson(json),
    level: level.getOrElse(0),
    on: level.map(x => x > 0).getOrElse(false),
    isDimmer: json.node_subtype === "MULTILEVEL"
  });
};

export default createLightFromJson;
export const createLightsFromJson = json =>
  Seq(json)
    .map(createLightFromJson)
    .sort(ascend(prop("name")))
    .reduce((acc, light) => acc.set(light.number, light), OrderedMap());
