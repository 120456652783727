/**
 *
 * Thermostat Model
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import { Record, OrderedMap, Seq } from "immutable";
import { add, ascend, prop } from "ramda";
import { clamp, isInRange, average } from "utils";
import zwaveDevicePropsFromJson, { defaultZwaveDeviceProps } from "./zwave";
export const FAN_MODES = {
  AUTO: "AUTO",
  ON: "ON"
};
export const MODES = {
  OFF: "OFF",
  COOL: "COOL",
  HEAT: "HEAT",
  AUTO: "AUTO"
};
export const SUBTYPES = {
  AUTO: "AUTO",
  STANDARD: "STANDARD"
};
export const TEMPATURE_RANGES = {
  HEAT: {
    MIN: 1,
    MAX: 99
  },
  COOL: {
    MIN: 1,
    MAX: 99
  },
  AUTO: {
    MIN: 1,
    MAX: 99
  }
};
export const defaultCoolingPoint = average([
  TEMPATURE_RANGES.COOL.MAX,
  TEMPATURE_RANGES.COOL.MIN
]);
export const defaultHeatingPoint = average([
  TEMPATURE_RANGES.HEAT.MAX,
  TEMPATURE_RANGES.HEAT.MIN
]);
export const defaultAutoPoint = average([
  TEMPATURE_RANGES.AUTO.MAX,
  TEMPATURE_RANGES.AUTO.MIN
]);
export const validCoolingTempature = isInRange(
  TEMPATURE_RANGES.COOL.MIN,
  TEMPATURE_RANGES.COOL.MAX
);
export const validHeatingTempature = isInRange(
  TEMPATURE_RANGES.HEAT.MIN,
  TEMPATURE_RANGES.HEAT.MAX
);
export const validAutoTempature = isInRange(
  TEMPATURE_RANGES.AUTO.MIN,
  TEMPATURE_RANGES.AUTO.MAX
);
export const getAutoCoolingPoint = add(-2);
export const getAutoHeatingPoint = add(2);
const createThermostat = Record({
  ...defaultZwaveDeviceProps,
  nodeType: "THERMOSTAT",
  currentTemperature: 0,
  setpointCooling: defaultCoolingPoint,
  setpointHeating: defaultHeatingPoint,
  fanMode: FAN_MODES.AUTO,
  mode: MODES.OFF
});

const createThermostatFromJson = json =>
  createThermostat({
    ...zwaveDevicePropsFromJson(json),
    currentTemperature: parseInt(json.current_temperature, 10),
    setpointCooling: clamp(
      json.setpoint_cooling,
      TEMPATURE_RANGES.COOL.MIN,
      TEMPATURE_RANGES.COOL.MAX
    ),
    setpointHeating: clamp(
      json.setpoint_heating,
      TEMPATURE_RANGES.HEAT.MIN,
      TEMPATURE_RANGES.HEAT.MAX
    ),
    fanMode: json.fan_mode,
    mode: json.mode
  });

export default createThermostatFromJson;
export const createThermostatsFromJson = json =>
  Seq(json)
    .map(createThermostatFromJson)
    .sort(ascend(prop("name")))
    .reduce(
      (acc, thermostat) => acc.set(thermostat.number, thermostat),
      OrderedMap()
    );
