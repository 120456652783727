/**
 *
 * PanelPermissions
 * @author Chad Watson
 *
 *
 */
import Maybe from "data.maybe";
import { Record } from "immutable";
import { always, anyPass, has } from "ramda";
import { normalizeApiBoolean } from "utils";
import { ARMING_TYPES } from "./arming";
export const createPanelPermissions = Record({
  lockdown: false,
  sensorReset: false,
  lockdownOverride: false,
  profiles: false,
  schedules: false,
  userCodes: false,
  doorAccess: false,
  cancelVerify: false,
  doorLockUnlock: false,
  bypass: false,
  armableAreas: Maybe.Nothing(),
  disarmableAreas: Maybe.Nothing(),
  accessibleDoors: Maybe.Nothing(),
});
export const createPanelPermissionsFromJson = (json) =>
  createPanelPermissions({
    lockdown: normalizeApiBoolean(json.lockdown),
    profiles: normalizeApiBoolean(json.profiles),
    schedules: normalizeApiBoolean(json.schedules),
    userCodes: normalizeApiBoolean(json.user_codes),
    doorAccess: normalizeApiBoolean(json.door_access),
    cancelVerify: normalizeApiBoolean(json.cancel_verify),
    doorLockUnlock: normalizeApiBoolean(json.door_lock_unlock),
    bypass: normalizeApiBoolean(json.bypass),
  });
export const createPanelPermissionsFromUserCodeAuthorityJson = (
  json,
  armingSystem
) =>
  createPanelPermissions({
    lockdown: json.lockdown,
    sensorReset: json.sensor_reset,
    lockdownOverride: json.lockdown_override,
    profiles: json.profiles_programming && armingSystem === ARMING_TYPES.AREA,
    schedules: json.schedules_programming,
    userCodes: json.user_codes_programming,
    doorAccess: json.door_access,
    cancelVerify: json.cancel_verify_alarm,
    doorLockUnlock: json.door_lock_unlock,
    bypass: json.bypass,
    armableAreas: Maybe.fromNullable(json.user_code_armable_areas).map(
      (array) => array.map(Number)
    ),
    disarmableAreas: Maybe.fromNullable(json.user_code_disarmable_areas).map(
      (array) => array.map(Number)
    ),
    accessibleDoors: Maybe.fromNullable(json.user_code_accessible_doors).map(
      (array) => array.map(Number)
    ),
  });
export const jsonResponseIncludesPanelPermissions = anyPass([
  has("lockdown"),
  has("profiles"),
  has("schedules"),
  has("user_codes"),
  has("door_access"),
  has("cancel_verify"),
  has("door_lock_unlock"),
  has("bypass"),
]);
export const fullAuthority = always(
  createPanelPermissions({
    lockdown: true,
    sensorReset: true,
    lockdownOverride: true,
    profiles: true,
    schedules: true,
    userCodes: true,
    doorAccess: true,
    cancelVerify: true,
    doorLockUnlock: true,
    bypass: true,
    armableAreas: Maybe.Nothing(),
    disarmableAreas: Maybe.Nothing(),
    accessibleDoors: Maybe.Nothing(),
  })
);
