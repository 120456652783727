/**
 *
 * Schedule Model
 * @author Chad Watson, Kyle Ellman
 *
 *
 */
import * as R from "ramda";
import Maybe from "data.maybe";
import { isBefore, addYears } from "date-fns/esm/fp";
import { SCHEDULE_TYPES } from "constants/index";
import { toInt } from "utils";
import { yesNoToBool, boolToYesNo } from "utils/models";
import { getMonthsFromIsoString, getDateFromIsoString } from "utils/dates";
import * as translate from "./translate";
export const ALL_AREAS_FOR_NON_AREA_SCHEDULES = [1];
export const NUMBER_RANGE = {
  MIN: 1,
  MAX: 99
};

const translateOptions = hasTwilight => ({
  [translate.TRANSLATE_OPTIONS.TWILIGHT]: hasTwilight,
  [translate.TRANSLATE_OPTIONS.END]: true,
  [translate.TRANSLATE_OPTIONS.END_TYPE]: true
});

export const availableNumbers = R.difference(
  R.range(NUMBER_RANGE.MIN, R.inc(NUMBER_RANGE.MAX))
);
export const firstAvailableNumber = R.compose(
  R.head,
  availableNumbers
);
const createTempDateFromDateString = R.curry(
  (now, dateString) =>
    new Date(
      Date.UTC(
        now.getFullYear(),
        getMonthsFromIsoString(dateString),
        getDateFromIsoString(dateString),
        12
      )
    )
);
const safeParseDateString = R.compose(
  R.chain(R.ifElse(R.isEmpty, R.always(Maybe.Nothing()), Maybe.of)),
  Maybe.fromNullable
);
const createTempDates = R.curry((now, json) => {
  const beginDate = safeParseDateString(json.begin_date).map(
    createTempDateFromDateString(now)
  );
  const endDate = R.sequence(Maybe.of, [
    beginDate,
    safeParseDateString(json.end_date).map(createTempDateFromDateString(now))
  ]).map(([begin, end]) => (isBefore(begin)(end) ? addYears(1)(end) : end));
  return {
    beginDate: beginDate.map(date => date.toISOString()).getOrElse(""),
    endDate: endDate.map(date => date.toISOString()).getOrElse("")
  };
});
const baseModel = R.applySpec({
  number: R.compose(
    toInt,
    R.prop("number")
  ),
  name: R.either(
    R.prop("name"),
    R.compose(
      R.concat("SCHEDULE "),
      R.toString,
      R.prop("number")
    )
  ),
  updatedName: R.always(null),
  updatedNumber: R.always(null),
  outputIds: R.either(R.prop("output_ids"), R.always([])),
  areaIds: R.either(R.prop("area_ids"), R.always([])),
  favoriteIds: R.either(R.prop("favorite_ids"), R.always([])),
  doorIds: R.either(R.prop("door_ids"), R.always([])),
  updating: R.always(false),
  deleting: R.always(false),
  type: R.always(SCHEDULE_TYPES.XR),
  holidayA: {
    begin: R.prop("hola_open"),
    end: R.prop("hola_close")
  },
  holidayB: {
    begin: R.prop("holb_open"),
    end: R.prop("holb_close")
  },
  holidayC: {
    begin: R.prop("holc_open"),
    end: R.prop("holc_close")
  },
  isTempSchedule: R.compose(
    yesNoToBool,
    R.defaultTo("N"),
    R.prop("temp_sched")
  )
});
const createXrScheduleFromJson = R.curry((now, hasTwilight, schedule) => ({
  ...baseModel(schedule),
  ...createTempDates(now, schedule),
  ...translate.fromJSON(translateOptions(hasTwilight))(schedule)
}));
/**
 * Builds a Schedule model
 * @param  {object} json response from schedules API
 * @return {object}
 */

export default createXrScheduleFromJson;
export const newSchedule = R.curry((now, number, hasTwilight) => ({
  ...baseModel({
    number
  }),
  ...createTempDates(now, {}),
  ...translate.newSchedule(translateOptions(hasTwilight))
}));
const baseScheduleToJson = R.applySpec({
  hola_open: R.path(["holidayA", "begin"]),
  hola_close: R.path(["holidayA", "end"]),
  holb_open: R.path(["holidayB", "begin"]),
  holb_close: R.path(["holidayB", "end"]),
  holc_open: R.path(["holidayC", "begin"]),
  holc_close: R.path(["holidayC", "end"]),
  temp_sched: R.compose(
    boolToYesNo,
    R.defaultTo(false),
    R.prop("isTempSchedule")
  ),
  begin_date: R.prop("beginDate"),
  end_date: R.prop("endDate")
});
const localKeysToOmit = [
  "updatedName",
  "updatedNumber",
  "updating",
  "deleting",
  "saving",
  "errors",
  "type",
  "holidayA",
  "holidayB",
  "holidayC"
];
/**
 * Builds a schedule api object
 * @param  {object} schedule new schedule object from dispatch
 * @return {object}
 */

export const toApiObject = R.converge(R.merge, [
  R.compose(
    translate.toJSON({
      rawBeginPrefix: "open",
      rawEndPrefix: "close"
    }),
    R.omit(localKeysToOmit)
  ),
  baseScheduleToJson
]);
export const saveErrors = R.applySpec({
  base: R.compose(
    R.defaultTo([]),
    R.path(["errors", "base"])
  ),
  slots: translate.slotsErrors
});
